import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import Typography from "@material-ui/core/Typography"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ width: 700, margin: "20px auto" }}>
      <Image alt="Bariamed not found" filename="404.png" />
    </div>
    <Typography
      variant="h3"
      color="inherit"
      style={{ marginBottom: 20, textAlign: "center" }}
    >
      ....Page not Found....
    </Typography>
  </Layout>
)

export default NotFoundPage
